import { CARD_INDEX, SPECIAL_EFFECTS, } from '@components/Card/Card.types';
import { GAME_CONFIG, } from '@components/Game/Game.types';
export const makeItemCard = (goalItemId, cardIndex, faceItem, language) => {
    return {
        id: `pair-${goalItemId}-${cardIndex}`,
        tag: 'item',
        goalItemId,
        faceItem,
        language,
        flipped: false,
        matched: false,
    };
};
export const isItemCard = (card) => {
    return 'faceItem' in card;
};
export const isSpecialEffectCard = (card) => {
    return 'specialEffect' in card;
};
/**
 * Generates a pair of cards based on a given goal item. Each card in
 * the pair is associated with the goal item and designed to be
 * matched together in a memory or matching game.
 *
 * @param {GoalItem} goalItem - The goal item object containing
 * properties and values that define the specific goal item.
 * @returns {CardPair} Returns an object containing two cards, each
 * linked to the provided goal item.
 */
export const makeCardPair = (goalItem) => {
    const card1 = makeItemCard(goalItem.item.id, 'front', goalItem.item.cue.text, goalItem.item.cue.language);
    const card2 = makeItemCard(goalItem.item.id, 'rear', goalItem.item.response.text, goalItem.item.response.language);
    return [card1, card2];
};
/**
 * Creates a mapping of card pairs indexed by the given goal
 * items. Each goal item is transformed into a pair of cards,
 * one for the front and ther other the rear.
 *
 * @param {GoalItem[]} goalItems - An array of goal item objects
 * @returns {CardPairMap} Returns an object that maps card IDs to
 * their respective card pairs.
 */
export const makeCardPairs = (goalItems) => {
    return Object.fromEntries(goalItems.map((goalItem) => [goalItem.item.id, makeCardPair(goalItem)]));
};
/**
 * Finds the pair ID associated with a given card's ID. This function is
 * used to determine the matching card that corresponds to the
 * given card's ID in the game.
 *
 * @param {string} cardId - The ID of the card for which to find the
 * corresponding pair ID.
 * @returns {string | null} Returns the pair ID if found, or null if no
 * matching pair exists for the given card ID.
 */
export const findPairId = (cardId) => {
    const match = cardId.match(/^(pair-\d+)-(front|rear)$/);
    if (!match) {
        return null;
    }
    const [base, side] = match.slice(1);
    const [front, rear] = CARD_INDEX;
    return `${base}-${side === front ? rear : front}`;
};
/**
 * Randomly selects a specified number of elements from an array.
 *
 * @template T The type of elements in the array.
 * @param {T[]} items - The array from which to select items.
 * @param {number} N - The number of items to select.
 * @returns {T[]} An array containing the randomly selected items.
 */
export const randomSelect = (items, N) => {
    const shuffledItems = [...items].sort(() => 0.5 - Math.random());
    return shuffledItems.slice(0, N);
};
/**
 * Shuffles an array using the Fisher-Yates algorithm.
 *
 * @template T The type of elements in the array.
 * @param {T[]} items - The array to shuffle.
 * @returns {T[]} A new array containing the shuffled elements from the input array.
 */
export const shuffle = (items) => {
    // Fisher-Yates Shuffle algorithm
    const outputItems = [...items];
    for (let i = outputItems.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [outputItems[i], outputItems[j]] = [outputItems[j], outputItems[i]];
    }
    return outputItems;
};
/**
 * Shuffles an array while keeping the element at a specified index in
 * its orignal place.
 *
 * @template T The type of elements in the array.
 * @param {T[]} cards - The array to shuffle.
 * @param {number} index - The index of the element to keep in place.
 * @returns {T[]} A new array containing the shuffled elements from
 * the input array, with the specified element in place.
 */
export const shuffle_except = (cards, index) => {
    const output = [...cards];
    const excludedCard = output.splice(index, 1)[0];
    for (let i = output.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [output[i], output[j]] = [output[j], output[i]];
    }
    output.splice(index, 0, excludedCard);
    return output;
};
/**
 * Constructs a dictionary of cards mapping from an array of card IDs
 * to their grid states. Each card property such as its grid index and
 * match status.
 *
 * @param {CardProps[]} cards - An array of Card instances, where
 * each prop defines an instance of CardProp type.
 * @returns {CardsDict} Returns an object mapping card IDs to their
 * respective grid states, including grid index, match status, and
 * flipped state.
 */
export function makeCardsDict(cards) {
    const cardsDict = {};
    for (let index = 0; index < cards.length; index++) {
        const card = cards[index];
        switch (card.tag) {
            case 'item':
                cardsDict[card.id] = {
                    gridIndex: index,
                    match: null,
                    flipped: false,
                };
                break;
            case 'special':
                cardsDict[card.id] = {
                    gridIndex: index,
                    flipped: false,
                };
                break;
            default:
                // eslint-disable-next-line no-case-declarations
                const exhaustiveCheck = card;
                throw new Error(`Unhandled case: ${exhaustiveCheck}`);
        }
    }
    return cardsDict;
}
/**
 * Calculates the number of revealed cards based on the total card
 * size and a minimum revealing count.  This function ensures that the
 * count does not exceed the total number of cards.
 *
 * @param {number} cardsSize - The total number of cards in the game.
 * @param {number} minReveal - The minimum number of cards that must
 * be revealed.
 * @returns {number} Returns the number of revealed cards, capped at
 * the total card size.
 */
export function revealedCardsCount(cardsSize, minReveal) {
    return Math.max(minReveal, cardsSize >> 3);
}
/**
 * Selects cards among which there are no matched parts.
 *
 * @param {CardProps[]} cards - An array of card properties
 * representing the available cards.
 * @param {number} revealedCount - The current count of cards that
 * have been revealed.
 * @returns {CardId[]} Returns card IDs meeting the no-match
 * requirement.
 */
export function soloSelectStrategyFn(cards, revealedCount) {
    const res = [];
    for (const card of shuffle([...cards])) {
        if (card.tag === 'item') {
            const pairId = findPairId(card.id);
            if (pairId && !res.includes(pairId) && res.length < revealedCount)
                res.push(card.id);
        }
    }
    return res;
}
/**
 * Selects cards randomly from an array of card instances based on the
 * current revealed count.  This function implements a random
 * selection strategy for card selection in the game, even if there
 * are matched cards (pairs) revealed.
 *
 * @param {CardProps[]} cards - An array of card instances
 * representing the available cards.
 * @param {number} revealedCount - The current count of cards that
 * have been revealed.
 * @returns {CardId[]} Returns IDs of such randomly selected card
 * instances
 */
const randomSelectStrategyFn = (cards, revealedCount) => {
    return randomSelect(cards.filter(({ tag }) => tag === 'item'), revealedCount).map(({ id }) => id);
};
const PICK_REVEALED_CARDS_STRATEGIES = {
    randomSelect: randomSelectStrategyFn,
    solosOnly: soloSelectStrategyFn,
};
/**
 * Creates a strategy function for picking revealed cards based on
 * specific game logic.
 *
 * @returns {function(CardProps[], number): CardId[]} A strategy
 * function that takes an array of card instances and the revealed
 * count, returning the selected card property or null if no selection
 * is made.
 */
function createPickRevealedCardsStrategy(strategyName) {
    return PICK_REVEALED_CARDS_STRATEGIES[strategyName];
}
/**
 * Picks the IDs of cards based on the current revealed count and the
 * provided array of card instances.  This function determines which
 * card IDs can be selected given the game's rules regarding revealed
 * cards.
 *
 * @param {CardProps[]} cards - An array of card properties
 * representing the available cards.
 * @param {number} revealedCount - The current count of cards that
 * have been revealed.
 * @returns {CardId[]} An array of selected card IDs based on the
 * selection strategy.
 */
function pickRevealedItemCards(cards, revealedCount) {
    const pickStrategy = createPickRevealedCardsStrategy(GAME_CONFIG.preRevealStrategy);
    return pickStrategy(cards, revealedCount);
}
/**
 * Creates a deep clone of the provided CardsDict instance: ensuring
 * that each cloned card state object is a new instance.
 *
 * @param {CardsDict} cardsDict - The original instance
 * @returns {CardsDict} Returns a new instance that is a deep copy of
 * the input, with each value independently copied.
 */
export function cloneCardsDict(cardsDict) {
    return Object.fromEntries(Object.entries(cardsDict).map(([cardId, cardState]) => {
        return [cardId, Object.assign({}, cardState)];
    }));
}
/**
 * Creates a deep copy of the given GridState instance.
 *
 * @param {GridState} gridState - The GridState instance to clone.
 * @returns {GridState} A new GridState instance that is a deep clone
 * of the input.
 */
export function cloneGridState(gridState) {
    return Object.assign(Object.assign({}, gridState), { cards: gridState.cards.map((card) => (Object.assign({}, card))), cardsDict: cloneCardsDict(gridState.cardsDict), timer: Object.assign({}, gridState.timer) });
}
function revealedCardsInPlace(cardsDict, revealedCards) {
    Object.entries(cardsDict).forEach(([cardId, cardState]) => {
        if (revealedCards.includes(cardId)) {
            cardState.flipped = true;
        }
    });
    return cardsDict;
}
function revealCards(cardsDict, revealedCards) {
    const clonedCardsDict = cloneCardsDict(cardsDict);
    return revealedCardsInPlace(clonedCardsDict, revealedCards);
}
function updateMatchProperty(cardsDict) {
    var _a;
    const newDict = {};
    for (const [cardId, cardState] of Object.entries(cardsDict)) {
        const pairId = findPairId(cardId);
        if (pairId && (cardState === null || cardState === void 0 ? void 0 : cardState.flipped) && ((_a = cardsDict[pairId]) === null || _a === void 0 ? void 0 : _a.flipped)) {
            newDict[cardId] = Object.assign(Object.assign({}, cardState), { match: pairId });
            newDict[pairId] = Object.assign(Object.assign({}, cardsDict[pairId]), { match: cardId });
        }
        else {
            newDict[cardId] = Object.assign({}, cardState);
        }
    }
    return newDict;
}
/**
 * Prepares the CardsDict instances for revealing a minimum number of
 * cards based on the provided criteria.
 * This function updates the cards dictionary to reflect which cards
 * should be flipped initially.
 *
 * @param {CardsDict} cardsDict - The current dictionary of card
 * states.
 * @param {CardProps[]} cards - An array of CardProp instances,
 * representing all possible cards.
 * @param {number} minReveal - The minimum number of cards that need
 * to be revealed.
 * @returns {CardsDict} An updated dictionary of card states
 * reflecting specified cards set to revealed.
 */
export function preReveal(cardsDict, cards, minReveal) {
    const cardsSize = cards.length;
    const revealedCount = revealedCardsCount(cardsSize, minReveal);
    const revealedCards = pickRevealedItemCards(cards, revealedCount);
    const newCardsDict = revealCards(cardsDict, revealedCards);
    return updateMatchProperty(newCardsDict);
}
/**
 * Refreshes the array of CardProp instances based on the current grid
 * states from the CardDict instance as the lookup table for card
 * states on the grid.
 * This function updates the card properties to reflect their latest
 * grid states in the game.
 *
 * @param {CardProps[]} cards - An array of card instances being
 * updated from the `cardDict` param.
 * @param {CardsDict} cardsDict - A look-up table of card grid states
 * that provides the latest updates for each card.
 * @returns {CardProps[]} An updated array of CardProp instances
 * reflecting the states from the cards dictionary.
 */
export function refreshCards(cards, cardsDict) {
    return cards.map((card) => {
        const cardState = cardsDict[card.id];
        switch (card.tag) {
            case 'item': {
                if (cardState) {
                    return Object.assign(Object.assign({}, card), { flipped: cardState.flipped || false, matched: card.tag === 'item' ? cardState.match !== null : false });
                }
                return card;
            }
            case 'special':
                return Object.assign(Object.assign({}, card), { flipped: cardState.flipped || false });
            default: {
                const exhaustiveCheck = card;
                throw new Error(`Unhandled case: ${exhaustiveCheck}`);
            }
        }
    });
}
/**
 * Updates the grid index of the cards based on the current state of
 * the cards look-up table, from the CardProp instances provided as
 * the source of true.
 *
 * @param {CardProps[]} cards - An array of card instances
 * representing the current cards in the grid.
 * @param {CardsDict} cardsDict - A lookup dictionary of card states
 * that provides the latest updates for each card.
 * @returns {CardsDict} An updated lookup dictionary of card states
 * with refreshed grid indices.
 */
export function refreshGridIndex(cards, cardsDict) {
    const outputCardsDict = Object.fromEntries(Object.entries(cardsDict).map(([id, cardState]) => [
        id,
        Object.assign({}, cardState),
    ]));
    cards.forEach((card, index) => {
        const cardId = card.id;
        const cardState = outputCardsDict[cardId];
        if (cardState) {
            cardState.gridIndex = index;
        }
    });
    return outputCardsDict;
}
/**
 * Checks if all flipped cards have matches in the provided array of
 * card instances.
 * This function returns true if every flipped item card is matched
 * (i.e. the player wins the game); otherwise, it returns false.
 *
 * @param {CardProps[]} cards - An array of card instances to check.
 * @returns {boolean} True if all flipped item cards have matches,
 * false otherwise.
 */
export const allFlippedHaveMatches = (cards) => cards.every((card) => isItemCard(card) ? !card.flipped || card.matched === true : true);
export function unflipMatchedCards(cardsDict) {
    let matchedPair = null;
    const updatedEntries = Object.entries(cardsDict).map(([id, cardState]) => [
        id,
        Object.assign({}, cardState),
    ]);
    const matchedCard = updatedEntries.find(([, cardState]) => cardState.match && cardState.flipped);
    if (matchedCard) {
        const [id1, cardState1] = matchedCard;
        const id2 = cardState1.match;
        const cardState2 = updatedEntries.find(([id]) => id === id2)[1];
        cardState1.flipped = cardState2.flipped = false;
        cardState1.match = cardState2.match = null;
        matchedPair = [id1, id2];
    }
    return [updatedEntries, matchedPair];
}
export function flipMatchedCards(cardsDict, excludedCards) {
    const updatedEntries = Object.entries(cardsDict).map(([id, cardState]) => [id, Object.assign({}, cardState)]);
    const unmatchedCards = updatedEntries.filter(([_id, cardState]) => !cardState.flipped &&
        !cardState.match &&
        (!excludedCards || !excludedCards.includes(_id)));
    unmatchedCards.some(([id1, cardState1]) => {
        const pairOfId1 = findPairId(id1);
        const entry2 = updatedEntries.find(([id]) => id === pairOfId1);
        if (!entry2)
            return false;
        const [id2, cardState2] = entry2;
        if (cardState2) {
            Object.assign(cardState1, { flipped: true, match: id2 });
            Object.assign(cardState2, { flipped: true, match: id1 });
            return true;
        }
        return false;
    });
    return updatedEntries;
}
/**
 * Unflips a pair of matched cards in the provided cards look-up
 * table/dictionary and flips another card in the process.
 * If no pair is found when the function is called, no unflipping
 * occurs. After unflipping, if there is no pair that can be
 * unflipped (except the pair of card just unflipped), the game will
 * flip one card that is a match to a currently flipped card on
 * the grid.
 *
 * @param {CardsDict} cardsDict - The current dictionary of card
 * states, including flipped and matched cards.
 * @returns {CardsDict} An updated look-up dictionary of card states
 * after 1) unflipping a pair and 2) flipping another pair/card.
 */
export function unflipAPairAndFlipAnother(cardsDict) {
    const [unflipped, matchedCards] = unflipMatchedCards(cardsDict);
    const afterUnflipping = Object.fromEntries(unflipped);
    const afterFlipping = Object.fromEntries(flipMatchedCards(afterUnflipping, matchedCards));
    return afterFlipping;
}
/**
 * Generates an array of special effect cards based on the specified
 * size.
 *
 * @param {number} size - The number of special effect cards to
 * create.
 * @returns {SpecialEffectCard[]} An array of newly created special
 * effect cards.
 */
export function makeSpecialEffectCards(size) {
    return Array.from({ length: size }, (_, index) => {
        const randomEffectIndex = Math.floor(Math.random() * SPECIAL_EFFECTS.length);
        return {
            id: `special-${index + 1}`,
            flipped: false,
            tag: 'special',
            specialEffect: SPECIAL_EFFECTS[randomEffectIndex],
        };
    });
}
/**
 * Checks if all elements in the provided array are of type
 * `GridState`
 *
 * @param {unknown[]} array - The array to check.
 * @returns {array is GridState[]} True if all elements in the array
 * are GridState instances, false otherwise.
 */
export function isGridStateArray(array) {
    return array.every((gridState) => gridState !== null &&
        typeof gridState === 'object' &&
        'cards' in gridState &&
        Array.isArray(gridState.cards) &&
        'cardsDict' in gridState &&
        typeof gridState.cardsDict === 'object' &&
        'flippings' in gridState &&
        typeof gridState.flippings === 'number' &&
        'timer' in gridState &&
        typeof gridState.timer === 'object' &&
        'gameResult' in gridState &&
        typeof gridState.gameResult === 'string' &&
        'moveCount' in gridState &&
        typeof gridState.moveCount === 'number' &&
        'countdownBoostRounds' in gridState &&
        typeof gridState.countdownBoostRounds === 'number' &&
        'retryCount' in gridState &&
        typeof gridState.retryCount === 'number');
}
