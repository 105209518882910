import { addHistoryEntry } from '@features/game/gameSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { allFlippedHaveMatches, cloneGridState, refreshCards, unflipAPairAndFlipAnother, } from '@utils/helpers';
export const playTrick = createAsyncThunk('grid/playTrick', async (_, { getState, dispatch }) => {
    const state = getState();
    const cardsDict = unflipAPairAndFlipAnother(state.grid.cardsDict);
    const cards = refreshCards(state.grid.cards, cardsDict);
    const gameResult = allFlippedHaveMatches(cards)
        ? 'won'
        : state.grid.gameResult;
    const newState = cloneGridState(Object.assign(Object.assign({}, state.grid), { cards,
        cardsDict,
        gameResult }));
    dispatch(addHistoryEntry(newState));
    return newState;
});
