import { createSlice } from '@reduxjs/toolkit';
import { cloneCardsDict, cloneGridState, refreshCards } from '@utils/helpers';
import { playTrick } from './thunks/playTrick';
import { preRevealCards } from './thunks/preRevealCards';
import { shuffleCards } from './thunks/shuffleCards';
import { updateCardsDict } from './thunks/updateCardsDict';
const initialState = {
    cards: [],
    cardsDict: {},
    flippings: 0,
    timer: {
        start: 0,
    },
    gameResult: 'playing',
    moveCount: 0,
    countdownBoostRounds: 0,
    retryCount: 0,
};
const gridSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setCardsAndGrid: (state, action) => {
            const [cards, cardsDict] = action.payload;
            return Object.assign(Object.assign({}, state), { cards,
                cardsDict, moveCount: 0, countdownBoostRounds: 0 });
        },
        incrementFlippings: (state) => {
            return Object.assign(Object.assign({}, state), { flippings: state.flippings + 1, timer: Object.assign(Object.assign({}, state.timer), { start: state.timer.start || Date.now() }), moveCount: state.moveCount + 1, countdownBoostRounds: Math.max(state.countdownBoostRounds - 1, 0), retryCount: Math.max(state.retryCount - 1, 0) });
        },
        resetTimer: (state) => (Object.assign(Object.assign({}, state), { timer: Object.assign(Object.assign({}, state.timer), { start: 0 }), countdownBoostRounds: 0 })),
        setGameResult: (state, action) => {
            return Object.assign(Object.assign({}, state), { gameResult: action.payload });
        },
        boostCountdown: (state, action) => {
            return Object.assign(Object.assign({}, state), { countdownBoostRounds: 1 + action.payload });
        },
        retry: (state) => {
            return Object.assign(Object.assign({}, state), { retryCount: 1 });
        },
        setSoundEnabled: (state, action) => {
            return Object.assign(Object.assign({}, state), { soundEnabled: action.payload });
        },
        refreshGridState: (_, action) => {
            return cloneGridState(action.payload);
        },
        updateSpecialEffectCardFlipped: (state, action) => {
            const [id, cardState] = action.payload;
            const cardsDict = cloneCardsDict(state.cardsDict);
            cardsDict[id] = cardState;
            const cards = refreshCards(state.cards, cardsDict);
            return Object.assign(Object.assign({}, state), { cards,
                cardsDict });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(preRevealCards.fulfilled, (_, action) => action.payload);
        builder.addCase(playTrick.fulfilled, (_, action) => action.payload);
        builder.addCase(updateCardsDict.fulfilled, (_, action) => action.payload);
        builder.addCase(shuffleCards.fulfilled, (_, action) => action.payload);
    },
});
export const { setCardsAndGrid, incrementFlippings, resetTimer, setGameResult, boostCountdown, retry, setSoundEnabled, refreshGridState, updateSpecialEffectCardFlipped, } = gridSlice.actions;
export default gridSlice.reducer;
