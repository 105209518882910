import { createSlice } from '@reduxjs/toolkit';
import { navigateHistory } from './thunks/navigateHistory';
import { setReplayMode } from './thunks/setReplayMode';
import { cloneGridState } from '@utils/helpers';
const initialState = {
    history: [],
    isReplayMode: false,
    currentHistoryIndex: 0,
};
export const gameSlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        addHistoryEntry: (state, action) => {
            state.history.push(action.payload);
        },
        refreshHistory: (state, action) => {
            const history = action.payload;
            return Object.assign(Object.assign({}, state), { history: history.map((gridState) => cloneGridState(gridState)) });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setReplayMode.fulfilled, (state, action) => {
            return Object.assign(Object.assign({}, state), action.payload);
        });
        builder.addCase(navigateHistory.fulfilled, (state, action) => {
            return Object.assign(Object.assign({}, state), action.payload);
        });
    },
});
export const { addHistoryEntry, refreshHistory } = gameSlice.actions;
export const selectHistory = (state) => state.game.history;
export default gameSlice.reducer;
