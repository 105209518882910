import { GAME_CONFIG } from '@components/Game/Game.types';
import { addHistoryEntry } from '@features/game/gameSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneGridState, preReveal, refreshCards } from '@utils/helpers';
export const preRevealCards = createAsyncThunk('grid/preRevealCards', async ({ cards, cardsDict }, { getState, dispatch }) => {
    const minPreRevealedCards = GAME_CONFIG.minPreRevealedCards;
    const preRevealedCardsDict = preReveal(cardsDict, cards, minPreRevealedCards);
    const refreshedCards = refreshCards(cards, preRevealedCardsDict);
    const { grid } = getState();
    const newState = cloneGridState(Object.assign(Object.assign({}, grid), { cards: refreshedCards, cardsDict: preRevealedCardsDict }));
    dispatch(addHistoryEntry(newState));
    return newState;
});
