import { addHistoryEntry } from '@features/game/gameSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { allFlippedHaveMatches, cloneCardsDict, cloneGridState, refreshCards, } from '@utils/helpers';
export const updateCardsDict = createAsyncThunk('grid/updateCardsDict', async (entries, { getState, dispatch }) => {
    const { grid: gridState } = getState();
    const entryCount = entries.length;
    if (!entryCount || entryCount > 2) {
        return gridState;
    }
    const keepingRetryCountFlag = entryCount === 2 || entries[0][0].startsWith('special');
    const cardsDict = cloneCardsDict(gridState.cardsDict);
    for (const [cardId, cardState] of entries) {
        cardsDict[cardId] = Object.assign(Object.assign({}, cardsDict[cardId]), cardState);
    }
    const cards = refreshCards(gridState.cards, cardsDict);
    const gameResult = allFlippedHaveMatches(cards)
        ? 'won'
        : gridState.gameResult;
    const retryCount = gridState.retryCount;
    const newState = cloneGridState(Object.assign(Object.assign({}, gridState), { cards,
        cardsDict, flippings: gridState.flippings + 1, timer: Object.assign(Object.assign({}, gridState.timer), { start: gridState.timer.start || Date.now() }), gameResult, moveCount: gridState.moveCount + 1, countdownBoostRounds: Math.max(gridState.countdownBoostRounds - 1, 0), retryCount: keepingRetryCountFlag
            ? retryCount
            : Math.max(retryCount - 1, 0) }));
    dispatch(addHistoryEntry(newState));
    return newState;
});
