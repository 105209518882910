import { configureStore } from '@reduxjs/toolkit';
import gridReducer from '@features/grid/gridSlice';
import gameReducer from '@features/game/gameSlice';
const store = configureStore({
    reducer: {
        grid: gridReducer,
        game: gameReducer,
    },
});
export default store;
