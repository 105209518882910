import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshGridState } from '@features/grid/gridSlice';
import { cloneGridState } from '@utils/helpers';
export const setReplayMode = createAsyncThunk('game/setReplayMode', async (replayMode, { getState, dispatch }) => {
    const { game } = getState();
    const gridState = cloneGridState(game.history[0]);
    const history = replayMode ? game.history : [gridState];
    dispatch(refreshGridState(gridState));
    return Object.assign(Object.assign({}, game), { isReplayMode: replayMode, history, currentHistoryIndex: 0 });
});
