import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaReact } from 'react-icons/fa';
const rotate = keyframes `
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const LoadingContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const RotatingIcon = styled(FaReact) `
  font-size: 4rem;
  animation: ${rotate} 2s infinite linear;
`;
const Loading = () => {
    return (_jsx(LoadingContainer, { role: "loading", children: _jsx(RotatingIcon, {}) }));
};
export default Loading;
