import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshGridState } from '@features/grid/gridSlice';
export const navigateHistory = createAsyncThunk('game/navigateHistory', async (direction, { getState, dispatch }) => {
    const { game } = getState();
    if (!game.isReplayMode)
        return game;
    switch (direction) {
        case 'backward': {
            const currentHistoryIndex = game.currentHistoryIndex > 0
                ? game.currentHistoryIndex - 1
                : game.currentHistoryIndex;
            dispatch(refreshGridState(game.history[currentHistoryIndex]));
            return Object.assign(Object.assign({}, game), { currentHistoryIndex });
        }
        case 'forward': {
            const currentHistoryIndex = game.currentHistoryIndex < game.history.length - 1
                ? game.currentHistoryIndex + 1
                : game.currentHistoryIndex;
            dispatch(refreshGridState(game.history[currentHistoryIndex]));
            return Object.assign(Object.assign({}, game), { currentHistoryIndex });
        }
        default:
            // eslint-disable-next-line no-case-declarations
            const exhaustiveCheck = direction;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
});
