import { jsx as _jsx } from "react/jsx-runtime";
import Loading from '@components/Game/Loading';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './app/store';
const LazyGame = React.lazy(() => import('@components/Game/Game'));
const App = () => {
    return (_jsx(Provider, { store: store, children: _jsx(React.StrictMode, { children: _jsx(Suspense, { fallback: _jsx(Loading, {}), children: _jsx(LazyGame, { gridWidth: 4 }) }) }) }));
};
export default App;
