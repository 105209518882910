import { addHistoryEntry } from '@features/game/gameSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneGridState, refreshGridIndex, shuffle_except, } from '@utils/helpers';
export const shuffleCards = createAsyncThunk('grid/shuffleCards', async (exceptionIndex, { getState, dispatch }) => {
    const { grid } = getState();
    const cards = shuffle_except(grid.cards, exceptionIndex);
    const cardsDict = refreshGridIndex(cards, grid.cardsDict);
    const newState = cloneGridState(Object.assign(Object.assign({}, grid), { cards,
        cardsDict, moveCount: grid.moveCount + 1, countdownBoostRounds: Math.max(grid.countdownBoostRounds - 1, 0) }));
    dispatch(addHistoryEntry(newState));
    return newState;
});
