export const GAME_CONFIG = {
    minPreRevealedCards: 3,
    defaultCountdown: 10000,
    countdownBoostRounds: 3,
    preRevealStrategy: 'solosOnly',
    countDownStrategy: 'refill',
    startWithShuffledCards: true,
};
export const GAME_RESULT = ['won', 'lost', 'playing'];
export const GAME_SOUNDS = {
    PairingSuccess: '/sounds/ding.wav',
    Victory: '/sounds/victory.wav',
    Timer: '/sounds/timer.wav',
    Shuffle: '/sounds/timer.wav',
    Retry: '/sounds/timer.wav',
    Trick: '/sounds/timer.wav',
};
